<template>
  <div class="d-flex justify-content-center">
    <div class="col-lg-3 mb-3">
      <!-- Dataset button -->
      <div class="card text-center chart-gradient fadeIn"  style="color:white;">
        <div class="card-header">Datasett</div>
        <div class="card-body">
          <button class="btn btn-secondary" @click="downloadData">Last ned datasett</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { downloadCSV } from "@/components/helpers/helpers.js";
import { mapGetters } from "vuex";

export default {
  name: "Datanedlasting",
  computed: {
    ...mapGetters("dashboard", ["coordinates"]),
  },
  methods: {
    downloadCSV,

    downloadData() {
      const header = [
        "lat",
        "lng",
        "radiant_ra",
        "radiant_dec",
        "radiant_ecl_lat",
        "radiant_ecl_long",
        "track_speed",
        "track_endheight",
        "radiant_shower",
        "date",
      ];
      const filename = "meteor_data.csv";

      // Convert the objects in the coordinates array into arrays
      const data = this.coordinates.map((coordinate) => {
        return [
          coordinate.lat,
          coordinate.lng,
          coordinate.radiant_ra,
          coordinate.radiant_dec,
          coordinate.radiant_ecl_lat,
          coordinate.radiant_ecl_long,
          coordinate.track_speed,
          coordinate.track_endheight,
          coordinate.radiant_shower,
          coordinate.date,
        ];
      });

      this.downloadCSV(header, data, filename);
    },
  },
};
</script>


<style scoped>
.fadeIn {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

</style>